import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausImBurgenland = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus im Burgenland?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher den
                    einzelnen Bundesländern und geben dir einen ersten Überblick über das dortige Angebot. Diesmal ist
                    das Burgenland an der Reihe.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – die aktuelle Tendenzen</h2>
                <p>
                    Aufgrund der aktuell günstigen Kreditzinsen liegt der Anstieg der Immobilienpreise in Österreich
                    seit dem vierten Quartal 2020 im zweistelligen Bereich. 12,3 % betrug dieser Anstieg im ersten
                    Quartal 2021, im zweiten war eine Teuerung von 11,7 % zu verzeichnen. Während sich der Anstieg der
                    Kaufpreise für Einfamilienhäuser außerhalb Wiens 2021 von 12,9 % im ersten auf 11,3 % im zweiten
                    Quartal verringerte, wurden gebrauchte Eigentumswohnungen in letzterem um 14,3 % teurer. Beobachten
                    lässt sich zudem, dass die Immobilienpreise außerhalb Wiens im Allgemeinen schneller wachsen als
                    innerhalb der Landeshauptstadt: Die Preise in Wien stiegen im ersten Quartal 2021 um 10,9, im
                    zweiten um 10,7 %. In den anderen Bundesländern kam es in diesem Zeitraum hingegen zu Wachstumsraten
                    von 14 bzw. 12,8 %. Dieser Trend gilt im Besonderen für den Neubauwohnungsmarkt: Stiegen die Preise
                    für neue Immobilien in Wien 2021 um 10,4 im ersten und von 10, 1 % zweiten Quartal, so kam es in den
                    übrigen Bundesländern zu einem Anstieg von 16,7 und 12,9 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Markt im Burgenland</h2>
                <p>
                    Ein Anstieg des Preises – sowohl von Häusern als auch von Eigentumswohnungen – ist im Burgenland
                    insbesondere an zwei Orten zu beobachten: Neusiedl am See und Eisenstadt (hier inklusive der
                    Umgebung). Generell hat im Burgenland längst eine preisliche Aufholjagd gegenüber anderen
                    Bundesländern begonnen. In fast allen Regionen steigen Immobilien - und Grundstückspreise aktuell.
                    Noch sind sie, im Vergleich zu den übrigen Bundesländer, aber nach wie vor recht günstig. Ein
                    Hauskauf im Burgenland lässt sich aus diesem Grund auch sehr gut als Wertanlage nutzen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Das kostet ein Haus im Burgenland</h2>
                <p>
                    Im Unterschied etwa zu Niederösterreich ist es im Burgenland nicht die Hauptstadt, die den höchsten
                    Quadratmeterpreis aufweist. Während du in Eisenstadt (Stadt) 1.437,88 € bezahlst, kostet dich ein
                    Quadratmeter Eigenheim in Neusiedl am See 1.806,11 €. Etwas günstiger ist der urbane Teil des
                    ebenfalls in Seenähe gelegenen Rust mit 1.317,75 €. Ebenfalls billiger sind die Orte Mattersburg und
                    Güssing mit 1.040,33 bzw. 1.110 € pro Quadratmeter.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausImBurgenland"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEinHausImBurgenland"}
                heading={"Was kostet ein Haus im Burgenland?"}
            />
        </Layout>
    );
};

export default WasKostetEinHausImBurgenland;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-ein-haus-im-burgenland", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
